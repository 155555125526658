<template>
    <div class="page">
        <el-form class="query-form" :inline="true" size="small" ref="inputForm" :model="inputForm"
                 label-width="100px">
            <el-form-item label="标注模型" prop="modelId">
                <el-select v-model="inputForm.modelId"
                           placeholder="请选择标注模型"
                           style="width: 100%"
                           clearable>
                    <el-option
                            v-for="item in modelIdList"
                            :key="item.id"
                            :label="item.modelName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="staterTime">
                <el-date-picker
                        clearable
                        v-model="inputForm.staterTime"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="endTime">
                <el-date-picker
                        clearable
                        v-model="inputForm.endTime"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="启动时间" prop="startUpTime">
                <el-date-picker
                        clearable
                        v-model="inputForm.startUpTime"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="输入搜索" prop="keyWord">
                <el-input v-model="inputForm.keyWord"
                          placeholder="请输入关键字(限50字)"
                          @keyup.enter.native="getDataList(1,1)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="getDataList(1,1)"
                           icon="el-icon-search">查询
                </el-button>
                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button type="primary" size="small"
                           v-show="hasPermissionButton(`collection:business:${typePage}:add`)"
                           icon='el-icon-plus' @click="addData(0)">新增
                </el-button>
                <el-button icon="el-icon-delete" size="small" type="danger" @click="deleteDate()"
                           v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">删除
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 375px)"
                    class="table"
                    :row-key="'id'"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="jobName" label="任务名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="modelId" label="标注模型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ getModelIdName(scope.row.modelId) }}
                    </template>
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="endTime" label="结束时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="startUpTime" label="启动时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remake" label="任务描述" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                                   @click="addData(1,scope.row,scope.$index)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(2, scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:edit`)">
                            修改
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="deleteDate(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">
                            删除
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="label(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:label`)">
                            手动标注
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>
        <add-manual ref="addManual" @refreshList="getDataList('',1)"></add-manual>
    </div>
</template>

<script>
import AddManual from "@/views/modules/dyyg/imageProcessing/addManual.vue";

export default {
    name: "manualAnnotation",
    components: {AddManual},
    props: {
        typePage: {
            type: String,
            default: 'manual',
        },
    },
    data() {
        return {
            inputForm: {
                modelId: '',
                staterTime: [],
                startTimeStart: '',
                startTimeEnd: '',

                endTime: [],
                endTimeStart: '',
                endTimeEnd: '',

                startUpTime: [],
                startUpTimeStart: '',
                startUpTimeEnd: '',

                keyWord: '',
                modelType: '1',
                jobType: '1',
            },

            modelIdList: [],
            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },

    mounted() {
        this.$axios(this.api.original.dyygmodelGetDyygModel, {
            modelState: 0,
            keyWord: '',
            current: 1,
            size: 99,
        }, 'post').then(res => {
            if (res.status) {
                this.modelIdList = res.data.records
            }
        })
        this.getDataList()
    },

    methods: {
        getModelIdName(id) {
            let row = this.modelIdList.filter(item => {
                return item.id == id
            })
            if (row.length) {
                return row[0].modelName
            }
        },

        //获取列表数据
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage == 1) {
                this.$refs.multipleTable.clearSelection()
            }
            let searchData = JSON.parse(JSON.stringify(this.inputForm))
            if (searchData.staterTime && searchData.staterTime.length == 2) {
                searchData.startTimeStart = searchData.staterTime[0]
                searchData.startTimeEnd = searchData.staterTime[1]
            } else {
                searchData.startTimeStart = ''
                searchData.startTimeEnd = ''
            }
            if (searchData.endTime && searchData.endTime.length == 2) {
                searchData.endTimeStart = searchData.endTime[0]
                searchData.endTimeEnd = searchData.endTime[1]
            } else {
                searchData.endTimeStart = ''
                searchData.endTimeEnd = ''
            }
            if (searchData.startUpTime && searchData.startUpTime.length == 2) {
                searchData.startUpTimeStart = searchData.startUpTime[0]
                searchData.startUpTimeEnd = searchData.startUpTime[1]
            } else {
                searchData.startUpTimeStart = ''
                searchData.startUpTimeEnd = ''
            }
            this.$delete(searchData, 'staterTime')
            this.$delete(searchData, 'endTime')
            this.$delete(searchData, 'startUpTime')
            this.loading = true;
            this.$axios(this.api.original.dyygmodeljobGetDyygModelJob, {
                ...searchData,
                current: this.current,
                size: this.size,
            }, 'post').then(res => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        addData(num, row) {
            this.$refs.addManual.init(num, row)
        },

        deleteDate(row) {
            if (!row && !this.dataListSelect.length) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            let ids
            if (row) {
                ids = [row.id]
            } else {
                ids = this.dataListSelect.map(item => {
                    return item.id
                })
            }
            this.$confirm(`确认删除所选数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.original.dyygmodeljobRemoveById, ids, 'post').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        //手动标注
        label(row) {
            this.$router.push({
                path: '/dyyg/imageProcessing/label',
                query: {id: row.id}
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', 2);
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },
    }
}
</script>

<style scoped lang="scss">
.aoidnfuia {
  ::v-deep .el-form-item__content {
    display: block !important;
    width: 393px !important;
  }
}
</style>